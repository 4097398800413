.site-footer {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}
.site-footer a {
  color: #768696;
  text-decoration: none;
}
.site-footer a:hover,
.site-footer a:active {
  color: #fff;
}
.footer-bg,
.footer-dots {
  display: none;
}
.site-footer-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.footer-social-links,
.footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
.footer-copyright {
  margin-bottom: 24px;
  flex-direction: column;
}
.footer-social-links {
  margin-bottom: 0;
}
.footer-social-links li {
  display: inline-flex;
}
.footer-social-links li + li {
  margin-left: 16px;
}
.footer-social-links li a {
  padding: 8px;
}
@media (min-width: 641px) {
  .site-footer::before {
    height: 202px;
  }
  .footer-bg,
  .footer-dots {
    display: block;
    position: absolute;
  }
  .footer-bg {
    bottom: 0;
    right: 0;
  }
  .footer-dots {
    bottom: 124px;
    right: 127px;
  }
  .site-footer-inner {
    justify-content: space-between;
  }
  .footer-social-links,
  .footer-copyright {
    flex: 50%;
  }
  .footer-copyright {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  .footer-social-links {
    justify-content: flex-end;
  }
}
